var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-settings"},[_c('v-dialog',{attrs:{"value":_vm.value,"max-width":"1320"},on:{"input":_vm.close}},[_c('v-card',{staticClass:"container container--fluid pt-0 pb-6"},[_c('v-card-title',{staticClass:"headline pb-4 pl-2"},[_vm._v(" "+_vm._s(_vm.$t('Groups settings'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search by group name'),"outlined":"","dense":"","placeholder":" ","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.filteredGroups,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"show-extra":"","fixed-header":"","height":"400px"},on:{"update:selection":function($event){_vm.selection=$event}},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}},{key:"group",fn:function(ref){
var row = ref.row;
return [(_vm.isSystemGroup(row.id))?_c('span',[_vm._v(" "+_vm._s(row.name)+" ")]):[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"primary--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('This group is system default. You can\'t delete it')))])]),(row.id === 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.$t('For traders in this group, transactions on PayOut do not require merchant confirmation. To complete the transaction, the trader only needs to confirm the sending of fiat')))}})]):_vm._e()]]}},{key:"usersAdded",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(row.profiles.length))])]}},{key:"delete",fn:function(ref){
var row = ref.row;
return [(_vm.isSystemGroup(row.id))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteGroup(row)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Delete group'))+" ")])]):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-6 group-settings__add-group"},[_c('h4',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t('Add new group'))+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"calc(100% - 520px)"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":_vm.$t('Group name'),"placeholder":" ","width":"100%"},on:{"input":_vm.handleNewGroupName},model:{value:(_vm.newGroup.name),callback:function ($$v) {_vm.$set(_vm.newGroup, "name", $$v)},expression:"newGroup.name"}})],1),_c('v-btn',{attrs:{"outlined":"","color":"primary","width":"220px","height":"40px","loading":_vm.newGroup.loader,"disabled":!_vm.newGroup.name},on:{"click":_vm.addNewGroup}},[_vm._v(" "+_vm._s(_vm.$t('Add group'))+" ")]),_c('div',{staticClass:"d-flex mt-2 ml-6 justify-center",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.errors.groupName)+" ")])])],1)]),_c('v-progress-linear',{attrs:{"active":_vm.loader,"rounded":false,"indeterminate":"","absolute":"","top":"","color":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }