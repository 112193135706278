import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  data() {
    return {
      personalFees: {
        show: false,
        userId: 0,
        data: [],
        userName: '',
        userEmail: '',
        userPassword: '',
      },
    };
  },

  computed: {
    ...mapState('tradersFees', ['personalDataRecord']),
    ...mapState('tradersCurrencies', {
      tradersCurrencies: 'data',
    }),
    showMerchantFees() {
      return this.rolesValue?.includes(constant.users.USER_ROLE.MERCHANT);
    },
    showTraderFees() {
      return this.rolesValue?.includes(constant.users.USER_ROLE.TRADER);
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersFees', [
      'editPersonalFees',
      'loadPersonalFeeDataById',
    ]),

    openCreatedUserModalFees(currentUserPersonalFees, userId, userName, userEmail, userPassword) {
      this.personalFees.show = true;
      this.personalFees.userId = userId;
      this.personalFees.data = currentUserPersonalFees;
      this.personalFees.userName = userName;
      this.personalFees.userEmail = userEmail;
      this.personalFees.userPassword = userPassword;
    },

    closeModal() {
      this.personalFees.show = false;
    },
  },
};
