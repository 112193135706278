//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';
import Filters from '~/components/filters/Index.vue';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';


export default {
  components: {
    Filters,
    TextField,
    Select,
    DateRange,
  },

  props: {
    isDisabled: { type: Boolean, default: undefined },
  },

  data() {
    return {
      filters: {
        searchString: '',
        nfaServiceTypeList: [],
        verificationStatusList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        warnings: false,
        blocked: null,
        ignoreUserTagList: [],
        deleted: null,
        roleList: [],
      },
      ignoreUserTagListModel: false,
      constant,
    };
  },

  computed: {
    ...mapState('users', ['filterLists']),

    verificationStatusList: () => constant.user.VERIFICATION_STATUS_LIST,
    nfaServiceTypeList: () => constant.users.NFA_SERVICE_TYPE_LIST,
    userRolesList: () => constant.users.USER_ROLE_LIST,
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters({
          ...data,
          blocked: !data.blocked ? null : true,
        });
        this.$emit('update');
      },
      deep: true,
    },
    ignoreUserTagListModel(boolean) {
      this.filters.ignoreUserTagList = [];
      if (boolean) this.filters.ignoreUserTagList = ['test', 'bot', 'dev'];
    },
  },

  created() {
    const verificationStatus = this.$route.query.verificationStatusList;
    const verificationStatusList = verificationStatus
      ? [Number(verificationStatus)]
      : [];
    const warnings = this.$route.query.warnings === 'true';

    if (verificationStatus || warnings) {
      this.$router.replace({ query: {} });
    }

    this.filters = {
      ...this.filters,
      verificationStatusList,
      warnings,
    };
  },

  methods: {
    ...mapActions('users', ['setFilters']),

    searchByRole() {
      this.$emit('search', this.roleList);
    },
  },
};
