//
//
//

import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('usersGroups', {
      loadUserGroups: 'getGroups',
    }),
  },

  async mounted() {
    // @if !process.env.VUE_APP_EXCLUDE_SECTION?.split(' ').includes('traders')
    await this.loadUserGroups();
    // @endif
  },
};
