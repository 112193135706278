//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';
import User from '~/components/User.vue';

export default {
  components: {
    User,
  },

  props: {
    value: { type: Boolean, default: false },
    showMerchantFees: { type: Boolean, default: false },
    showTraderFees: { type: Boolean, default: false },
    id: { type: Number, default: null },
    data: { type: Array, default: () => ([]) },
    currencies: { type: Array, default: () => ([]) },
    userId: { type: Number, default: 0 },
    userName: { type: String, default: '' },
    userEmail: { type: String, default: '' },
    userPassword: { type: String, default: '' },
  },

  data() {
    return {
      input: {
        currencyId: 1,
      },
      constant,
    };
  },

  computed: {
    ...mapState('tradersFees', { fees: 'data' }),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sellFeePercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.sellFeePercent ?? 0}%`;
    },
    buyFeePercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.buyFeePercent ?? 0}%`;
    },
    sellProfitPercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.sellProfitPercent ?? 0}%`;
    },
    buyProfitPercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.buyProfitPercent ?? 0}%`;
    },
    isApplyPossible() {
      if (this.showMerchantFees) {
        return this.input.sellFeePercent !== '' && this.input.buyFeePercent !== '' && this.input.currencyId;
      }
      if (this.showTraderFees) {
        return this.input.sellProfitPercent !== '' && this.input.buyProfitPercent !== '' && this.input.currencyId;
      }
      return false;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.input = {};
      }
    },
  },

  mounted() {
    this.setGeneralProgress(true);
    this.loadFees();
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setGeneralProgress',
    ]),
    ...mapActions('tradersFees', {
      loadFees: 'loadData',
    }),

    async copyMessage(message) {
      await navigator.clipboard.writeText(message);
      this.setSuccessNotification('Сopied to clipboard');
    },

    close() {
      this.$emit('close');
    },

    redirectToUserPage() {
      this.$router.push({
        path: `/user/${this.$props.id}`,
        query: { edit_fees: true },
      });
    },
  },
};
