export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Group',
    },
    cell: {
      type: 'slot',
      name: 'group',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Users added',
    },
    cell: {
      type: 'slot',
      name: 'usersAdded',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Delete',
    },
    cell: {
      type: 'slot',
      name: 'delete',
    },
  },
];
