//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapGetters } from 'vuex';
import WarningAlert from '~/components/users/WarningAlert.vue';
import BlockAlert from '~/components/users/BlockAlert.vue';
import NfaList from '~/components/users/NfaList.vue';
import UTMList from '~/components/users/UTMList.vue';
import Activity from '~/components/users/Activity.vue';
import User from '~/components/User.vue';
import constant from '~/const';
import { extraTableIdentificatorList } from './exclude';

export default {
  components: {
    WarningAlert,
    BlockAlert,
    NfaList,
    UTMList,
    Activity,
    User,
  },

  props: {
    user: { type: Object, default: () => ({}) },
    deleted: { type: Boolean, default: false },
  },

  data() {
    return {
      loader: true,
      userData: [],
    };
  },

  computed: {
    ...mapGetters('common', ['getLanguageById']),
    ...mapState('common', ['generalLoader', 'generalProgress']),
    ...mapState('usersGroups', ['groups']),
    ...mapGetters('usersGroups', ['usersGroups']),

    userRoles() {
      if (this.$props.deleted) {
        return (this.userData?.roles ?? []).map((e) => this.$utils.getCaption(constant.users.USER_ROLE_LIST, e)).join(', ');
      }
      return (this.user.extendedUser?.roles ?? []).map((e) => this.$utils.getCaption(constant.users.USER_ROLE_LIST, e)).join(', ');
    },
    userIsMerchantOrTrader() {
      const rolesForGroup = [constant.users.USER_ROLE.MERCHANT, constant.users.USER_ROLE.TRADER];
      return this.user.extendedUser?.roles && this.user.extendedUser?.roles.some((role) => rolesForGroup.includes(role));
    },
    userGroups() {
      return this.usersGroups ? this.usersGroups[this.user.id] : [];
    },
    regDate() {
      const { dateCreated } = this.$props.deleted ? this.user : this.user.extendedUser;
      return this.$utils.getFullDate(dateCreated);
    },

    verificationRequestDate() {
      const { verificationDate } = this.$props.deleted ? this.user : this.user.extendedUser;
      if (!verificationDate) {
        return '–';
      }
      return this.$utils.getDate(verificationDate);
    },

    userWarningList() {
      const { userWarningList } = this.$props.deleted ? this.userData : this.user.extendedUser;
      if (userWarningList && userWarningList.length) {
        return userWarningList;
      }
      return [];
    },

    userIpData() {
      const { ipAddressData } = this.$props.deleted ? this.userData : this.user.extendedUser;
      return ipAddressData;
    },

    userLockList() {
      try {
        const { userLockList } = this.$props.deleted ? this.userData : this.user.extendedUser;
        return userLockList;
      } catch (error) {
        return [];
      }
    },

    hasUTMList() {
      if (this.$props.deleted) {
        return Array.isArray(this.userData.utmList);
      }
      return Array.isArray(this.user.extendedUser.utmList);
    },

    tableIdentificatorList() {
      const { identificatorList } = this.user;
      return [
        { key: 'Email:', value: this.$utils.isIdentificatorType(identificatorList, 0) },
        { key: 'Mobile Number:', value: this.$utils.isIdentificatorType(identificatorList, 3) },
        { key: 'Telegram Id:', value: this.$utils.isIdentificatorType(identificatorList, 1) },
      ].filter((e) => extraTableIdentificatorList.includes(e.key));
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    ...mapActions('users', {
      loadUserAction: 'loadUser',
      usersUnlockAction: 'usersUnlock',
      usersUnblockAction: 'usersUnblock',
      getUsersAction: 'getUsers',
    }),
    ...mapActions('usersGroups', {
      loadUserGoups: 'getGroups',
      addUsersToGroup: 'addUsersToGroup',
      removeUsersFromGroup: 'removeUsersFromGroup',
    }),
    ...mapActions('common', [
      'confirmAction',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),

    async loadData() {
      const id = this.$props.deleted ? this.user.userId : this.user.id;
      this.loader = true;
      this.setGeneralProgress(true);
      this.userData = await this.loadUserAction({ userId: id });
      this.loader = false;
      this.setGeneralProgress(false);
    },

    async setGroup(groups, userId) {
      const toSet = groups.filter((e) => (!this.userGroups || !this.userGroups.includes(e)))[0];
      const toRemove = (this.userGroups || []).filter((e) => !groups.includes(e))[0];

      this.setGeneralProgress(true);
      if (toSet) {
        await this.addUsersToGroup({
          userId: [userId],
          id: toSet,
        });
      }
      if (toRemove) {
        await this.removeUsersFromGroup({
          userId: [userId],
          id: toRemove,
        });
      }
      await this.loadUserGoups();
      this.setGeneralProgress(false);
    },

    unlockUser(userId, type) {
      this.confirmAction({
        text: 'Are you sure you want to unlock user?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.loader = true;
          this.setGeneralProgress(true);
          try {
            if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
              await this.usersUnblockAction({
                userIds: [userId],
                comment: prompt,
              });
            } else {
              await this.usersUnlockAction({
                userIds: [userId],
                comment: prompt,
                type,
              });
            }
            await this.getUsersAction();
            await this.loadUserAction({ userId });
            this.setSuccessNotification('User unlocked');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    getCountryCode(country) {
      if (country) return country.slice(0, 2);
      return '';
    },
  },
};
