var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2 pl-3 d-flex flex-column"},[_c('v-row',{staticClass:"flex-nowrap"},[_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('Add users to a group')))]),_c('UserSelect',{staticClass:"mt-6",attrs:{"hide-details":true,"multiple":true,"width":"100%","label":_vm.$t('Username')},on:{"input":function($event){_vm.addingArbitrator.input.userId = $event}},model:{value:(_vm.mapOfIdLists[_vm.data.id]),callback:function ($$v) {_vm.$set(_vm.mapOfIdLists, _vm.data.id, $$v)},expression:"mapOfIdLists[data.id]"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"outlined":"","color":"green","disabled":!(_vm.mapOfIdLists[_vm.data.id] && Object.values(_vm.mapOfIdLists[_vm.data.id]).length)},on:{"click":function($event){$event.stopPropagation();return _vm.addUsersToGroupHandler(_vm.data.id, _vm.data.name)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.getAddButtonText(_vm.mapOfIdLists[_vm.data.id])))])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('Users in this group')))]),_c('v-text-field',{staticClass:"mt-6",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search user in group by name'),"outlined":"","dense":"","placeholder":" ","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"mt-3"},[_vm._l((_vm.filteredProfiles),function(profile){return [(profile.userId && profile.userName && _vm.showUserChip(_vm.data.id, profile.userId, profile.userName))?_c('v-tooltip',{key:'group-profile-' + profile.userId,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"grey--text text--lighten-2 ma-1 group-settings__chip",class:{
                  'group-settings__chip--selected': _vm.selected[_vm.data.id] && _vm.selected[_vm.data.id][profile.userId],
                },attrs:{"outlined":!(_vm.selected[_vm.data.id] && _vm.selected[_vm.data.id][profile.userId]),"close":""},on:{"click:close":function($event){return _vm.removeUserFromGroup({
                  groupId: _vm.data.id,
                  userId: profile.userId,
                })},"click":function($event){return _vm.clickHandler(_vm.data.id, profile.userId)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(profile.userName)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(profile.userId)+" ")])]):_vm._e()]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }