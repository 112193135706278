//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DOMPurify from 'dompurify';

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';
import tableConfig from './tableConfig';
import Extra from './Extra.vue';

export default {

  components: {
    Extra,
  },
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      newGroup: {
        name: '',
        loader: false,
      },
      loader: false,
      constant,
      showedExtra: [],
      selected: {},
      groupUserSearch: {},
      mapOfIdLists: {},
      tableConfig,
      selection: [],
      search: '',
      errors: {
        groupName: '',
      },
    };
  },

  computed: {
    ...mapState('usersGroups', ['groups']),
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    filteredGroups() {
      if (!this.search) {
        return this.groups;
      }
      const searchLower = this.search.toLowerCase();
      return this.groups.filter((group) => group.name.toLowerCase().includes(searchLower));
    },
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),

    ...mapActions('usersGroups', ['addGroup', 'getGroups', 'removeGroup', 'removeUsersFromGroup', 'addUsersToGroup']),

    sanitize(text) {
      return DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });
    },

    isSystemGroup(id) {
      if (id === 1 || id === 2) {
        return false;
      }
      return true;
    },

    getGroupNamesRecommendation() {
      const { name } = this.newGroup;
      const randomAdjective = this.constant.adjectives.ADJECTIVES_LIST[Math.floor(Math.random() * this.constant.adjectives.ADJECTIVES_LIST.length)];
      const randomNumber = Math.floor(Math.random() * 100);
      return `${name}${randomNumber}${randomAdjective}`;
    },

    handleNewGroupName() {
      // Проверяем, существует ли группа с таким же именем
      const groupExists = this.groups.some((group) => group.name === this.newGroup.name);

      if (groupExists) {
        // Если группа с таким именем существует, устанавливаем ошибку
        this.errors.groupName = this.$t('Group with this name already exists. Maybe', { group: this.getGroupNamesRecommendation() });
      } else {
        this.errors.groupName = '';
      }
    },

    close() {
      this.$emit('input', false);
    },

    async deleteGroup(group) {
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to delete group', { group: group.name }),
        callback: async () => {
          this.loader = true;
          try {
            await this.removeGroup(group);
            await this.getGroups();
            this.setSuccessNotification('Group successfuly deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
    },

    async addNewGroup() {
      this.newGroup.loader = true;
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to create group', { group: this.newGroup.name }),
        callback: async () => {
          this.loader = true;
          try {
            const { name } = this.newGroup;
            await this.addGroup({ Name: name });
            await this.getGroups();
            this.setSuccessNotification('Group successfuly created');
            this.newGroup.name = '';
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
      this.newGroup.loader = false;
    },
  },
};
