//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import openModalMixin from '~/views/UserPersonalFees/openModalMixin';
import UserCreateModalMixin from '~/views/UserCreatedModal/openModalMixin';
import UserPersonalFeesModal from '~/views/UserPersonalFees/Modal.vue';
import UserCreatedModal from '~/views/UserCreatedModal/Modal.vue';
import SettingFeesAndLimits from '~/components/modals/SettingFeesAndLimits.vue';
import Pagination from '~/components/Pagination.vue';
import Sorting from '~/components/Sorting.vue';
import Table from '~/components/ui/Table.vue';
import UsersTagsList from '~/views/Users/UsersTagsList.vue';
import UsersGroupsList from '~/views/Users/UsersGroupsList.vue';
import Extra from '~/views/Users/Extra.vue';
import UsersLock from '~/views/Users/modals/UsersLock.vue';
import User from '~/components/User.vue';
import Tag from '~/components/tags/Tag.vue';
import AddTagToUser from '~/components/tags/AddTagToUser.vue';
import DropdownMenu from '~/components/ui/dropdown-menu/Index.vue';
import GetReport from '~/components/GetReport.vue';
import createUserModal from './createUserModal.vue';
// import TableKeyValue from '~/components/ui/TableKeyValue.vue';

import UsersFilters from './UsersFilters.vue';
import CustomizeTags from './modals/CustomizeTags.vue';
import SettingStaking from './modals/SettingStaking.vue';

import tableConfig from './usersTableConfig';
import deletedTableConfig from './deletedUsersTableConfig';
import actionsConfig from './usersActionsConfig';

export default {
  components: {
    UsersFilters,
    CustomizeTags,
    SettingStaking,
    SettingFeesAndLimits,
    Pagination,
    Sorting,
    Table,
    UsersTagsList,
    UsersGroupsList,
    Extra,
    UsersLock,
    User,
    Tag,
    AddTagToUser,
    DropdownMenu,
    GetReport,
    UserPersonalFeesModal,
    UserCreatedModal,
    createUserModal,
    // TableKeyValue,
  },

  mixins: [openModalMixin, UserCreateModalMixin],

  data() {
    return {
      tableConfig,
      deletedTableConfig,
      actionsConfig,
      showDeleted: false,
      selection: [],
      usersLock: {
        modal: false,
        type: 0,
      },
      constant,
      customizingTags: {
        modal: false,
      },
      settingFeesAndLimits: {
        modal: false,
      },
      settingStaking: {
        modal: false,
        loading: false,
      },
      createUserModal: {
        show: false,
        loading: false,
        model: {},
      },
      // successCreatedUserModal: {
      //   show: false,
      //   model: {},
      // },
      errors: {
        createUserModal: {
          email: undefined,
          userName: undefined,
          password: undefined,
          roles: undefined,
        },
      },
      // currentUserPersonalFees: {
      //   takerPayIn: 0,
      //   takerPayOut: 0,
      //   makerPayIn: 0,
      //   makerPayOut: 0,
      // },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader', 'generalProgress', 'languages']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('users', ['getUserTagsByIds', 'requestData']),

    ...mapState('users', ['page', 'pages', 'users', 'sort', 'sortList', 'userTagsList', 'deletedUsers', 'deletedUsersPages', 'deletedUsersPage']),
    ...mapState('usersGroups', ['groups']),

    showMerchantFees() {
      return this.createUserModal.model?.roles?.includes(constant.users.USER_ROLE.MERCHANT);
    },

    showTraderFees() {
      return this.createUserModal.model?.roles?.includes(constant.users.USER_ROLE.TRADER);
    },

    showPersonalFees() {
      return this.showMerchantFees || this.showTraderFees;
    },

    // createdUserTableData() {
    //   const dataList = Object.entries(this.successCreatedUserModal.model).map(([key, value]) => {
    //     if (key === 'roles' && value?.length) {
    //       const roles = value.map((roleId) => this.$t(`const.${constant.users.USER_ROLE_CAPTION_IOTA[roleId]}`)).join(', ');
    //       return { key, value: roles };
    //     }
    //     return { key, value };
    //   });
    //   return dataList;
    // },
  },

  async mounted() {
    this.setGeneralLoader(true);
    const { page } = this.$route.query;
    const numberPage = Number(page);
    if (numberPage) {
      this.setPageAction(numberPage);
    }
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('users', {
      getUsersAction: 'getUsers',
      getDeletedUsersAction: 'getDeletedUsers',
      getUserTagsListAction: 'getUserTagsList',
      setPageAction: 'setPage',
      setDeletedUsersPageAction: 'setDeletedUsersPage',
      setSortAction: 'setSort',
      removeTagsFromUserAction: 'removeTagsFromUser',
      addTagsToUserAction: 'addTagsToUser',
      usersLockAction: 'usersLock',
      usersBlockAction: 'usersBlock',
      updateUserTransactionLimitAction: 'updateUserTransactionLimit',
      deleteUserTransactionLimitAction: 'deleteUserTransactionLimit',
      createUserAction: 'createUser',
      findByRole: 'getUserByRole',
    }),
    ...mapActions('userStakingStats', {
      updateParaminingPercAction: 'updateParaminingPerc',
    }),
    ...mapActions('p2pArbitratorsList', {
      loadP2pArbitratorsList: 'loadData',
    }),
    ...mapActions('tradersFees', {
      editPersonalFees: 'editPersonalFees',
      loadPersonalFees: 'loadPersonalFeeDataById',
    }),
    ...mapActions('usersGroups', {
      loadUserGroups: 'getGroups',
      addUsersToGroup: 'addUsersToGroup',
      removeUsersFromGroup: 'removeUsersFromGroup',
      statsProfileById: 'statsProfileById',
      addGroup: 'addGroup',
    }),

    closeUserCreateModal() {
      this.closeModal();
    },

    showDeletedUsers() {
      this.showDeleted = !this.showDeleted;
    },

    async getUsers(data = {}) {
      await this.setGeneralLoader(true);
      await this.getUsersAction(data);
      await this.getDeletedUsersAction(data);
      await this.setGeneralLoader(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.getUsers();
    },

    searchByRole(data) {
      this.findByRole({ roles: { ...data } });
    },

    applySort(data) {
      this.setSortAction(data);
      this.getUsers();
    },

    setPage(value) {
      this.setPageAction(value);

      const query = { page: value };
      const extendedRequestData = {
        getTotal: false,
      };

      this.$router.replace({ query });
      this.getUsers(extendedRequestData);
    },

    setDeletedUsersPage(value) {
      this.setDeletedUsersPageAction(value);

      const query = { page: value };
      const extendedRequestData = {
        getTotal: false,
      };

      this.$router.replace({ query });
      this.getUsers(extendedRequestData);
    },

    setFeesAndLimits() {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No users selected');
        return;
      }

      this.settingFeesAndLimits.modal = true;
    },

    openFeesModal(data) {
      this.openCreatedUserModalFees([], ...data);
    },

    async applySetFeesAndLimits({ state, currencyId }) {
      const { selection } = this;
      const payload = [];
      selection.forEach((u) => {
        state.forEach((s) => {
          payload.push({
            ...s,
            userId: u,
            currencyId,
          });
        });
      });
      this.setGeneralProgress(true);
      try {
        await Promise.all(
          payload
            .filter((e) => constant.transactions.LIMIT_TYPE_LIST.some((l) => e[`limit${l.id}`]))
            .map((e) => this.updateUserTransactionLimitAction(e)),
        );
        this.settingFeesAndLimits.modal = false;
        this.setSuccessNotification('Limits changed successfully');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    customizeTags() {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No users selected');
        return;
      }
      this.customizingTags.modal = true;
    },

    applyAddTags(userTagList) {
      this.confirmAction({
        title: 'Are you sure you want to add tags to selected users?',
        callback: async () => {
          const { selection } = this;

          this.customizingTags.modal = false;
          this.setGeneralLoader(true);
          await this.addTagsToUserAction({
            userIdList: selection,
            userTagList,
          });
          await this.getUsersAction({});
          this.setGeneralLoader(false);
          this.setSuccessNotification('Tags to selected users successfully added');
        },
      });
    },

    applyRemoveTags(userTagList) {
      this.confirmAction({
        title: 'Are you sure you want to remove tags from selected users?',
        callback: async () => {
          const { selection } = this;

          this.customizingTags.modal = false;
          this.setGeneralLoader(true);
          await this.removeTagsFromUserAction({
            userIdList: selection,
            userTagList,
          });
          await this.getUsersAction({});
          this.setGeneralLoader(false);
          this.setSuccessNotification('Tags from selected users successfully removed');
        },
      });
    },

    setStaking() {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No users selected');
        return;
      }
      this.settingStaking.modal = true;
    },

    async applySetStaking(data) {
      const { selection } = this;

      this.confirmAction({
        title: 'Are you sure you want to change staking percent?',
        callback: async () => {
          const payload = {
            userIdList: selection,
            ...data,
          };

          this.setGeneralProgress(true);
          try {
            this.settingStaking.loading = true;
            await this.updateParaminingPercAction(payload);
            this.settingStaking.loading = false;
            this.settingStaking.modal = false;
            await this.getUsersAction({});
            this.setSuccessNotification('Staking percent updated');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    confirmBlock(type) {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No users selected');
        return;
      }
      this.usersLock.modal = true;
      this.usersLock.type = type;
    },

    async block({ comment, blockTime }) {
      const { type } = this.usersLock;
      this.setGeneralLoader(true);
      try {
        if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
          await this.usersBlockAction({
            userIds: this.selection,
            comment,
          });
        } else {
          await this.usersLockAction({
            userIds: this.selection,
            comment,
            blockTime,
            type,
          });
        }
        await this.getUsersAction();
        this.setSuccessNotification('Users locked');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralLoader(false);
    },

    async removeTagFromUser(userId, tagId) {
      this.setGeneralLoader(true);
      await this.removeTagsFromUserAction({
        userId,
        userTagList: [tagId],
      });
      await this.getUsersAction();
      await this.getUserTagsListAction();
      this.setGeneralLoader(false);
      this.setSuccessNotification('Tag successfully removed');
    },

    async addTagToUser(tagId, userId) {
      this.setGeneralLoader(true);
      await this.addTagsToUserAction({
        userIdList: [userId],
        userTagList: [tagId],
      });
      await this.getUsersAction();
      await this.getUserTagsListAction();
      this.setGeneralLoader(false);
      this.setSuccessNotification('Tag successfully added');
    },

    hasDisabledNfa(row) {
      const disabled = row.nfaList.find((e) => !e.enabled);
      return !!disabled;
    },

    getVerificationColor(verificationStatus) {
      const {
        UNVERIFIED,
        IN_PROCESS,
        REQUIRES_DATA,
        VERIFIED,
        VERIFICATION_REQUEST,
        VERIFIED_VERIFICATION_REQUEST,
        REJECTED,
      } = constant.user.VERIFICATION_STATUS;
      if (
        [UNVERIFIED].includes(verificationStatus)
      ) return '';
      if (
        [IN_PROCESS].includes(verificationStatus)
      ) return 'primary';
      if (
        [
          REQUIRES_DATA,
          VERIFICATION_REQUEST,
          VERIFIED_VERIFICATION_REQUEST,
        ].includes(verificationStatus)
      ) return 'warning';
      if (
        [VERIFIED].includes(verificationStatus)
      ) return 'success';
      if (
        [REJECTED].includes(verificationStatus)
      ) return 'error';
      return '';
    },

    gotoVerification(id) {
      const route = this.$router.resolve({
        name: 'user-id',
        params: { id },
        query: { openVerification: true },
      });
      window.open(route.href, '_blank');
    },

    openCreateUserModal() {
      this.errors.createUserModal.email = undefined;
      this.errors.createUserModal.userName = undefined;
      this.errors.createUserModal.password = undefined;
      this.errors.createUserModal.roles = undefined;

      this.createUserModal.model = {
        email: '',
        userName: '',
        password: this.$utils.generatePassword(),
        roles: [],
        referral: '',
        language: 0,
        timeZone: 'Asia/Riyadh',
      };
      this.createUserModal.show = true;
    },

    applyEditPersonalFees() {
      const userId = String(this.successCreatedUserModal.model.userId);
      this.confirmAction({
        title: `Are you sure you want to update fees of user with id ${userId}?`,
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.editPersonalFees({ ...this.currentUserPersonalFees, userId });
            await this.loadPersonalFees({ userId });
            this.setSuccessNotification(`Personal fees of user with id ${userId} is edited`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

  },
};
