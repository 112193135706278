//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';
import UserSelect from '~/components/UserSelect.vue';

export default {
  components: {
    UserSelect,
  },

  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {
      constant,
      groupUserSearch: {},
      selected: {},
      mapOfIdLists: {},
      search: '',
      addingArbitrator: {
        input: {
          userId: 0,
        },
      },
    };
  },

  computed: {
    ...mapState('users', ['searchedUsers']),

    filteredProfiles() {
      if (!this.search) {
        return this.$props.data.profiles;
      }
      const searchLower = this.search.toLowerCase();
      return this.$props.data.profiles.filter((profile) => profile.userName.toLowerCase().includes(searchLower));
    },
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),
    ...mapActions('usersGroups', ['addGroup', 'getGroups', 'removeUsersFromGroup', 'addUsersToGroup']),

    getAddToGroupText(arr, groupName) {
      return this.$t('Are you sure you want to add these users to the group', {
        group: groupName,
        users: this.getListOfIds(arr),
      });
    },

    getListOfIds(arr) {
      // Проверяем, если количество элементов в массиве не превышает 20
      if (arr.length <= 20) {
        // Возвращаем имена всех элементов, объединенные запятой
        // и добавляем последнее имя, если в массиве более одного элемента
        return arr.map((item) => item.userName).join(', ');
      }
      // Если количество элементов в массиве больше 20
      const maxNamesToShow = 50; // Максимальное количество имен для отображения (50)
      // Возвращаем имена первых (maxNamesToShow) элементов, объединенные запятой,
      // и добавляем сообщение о оставшихся элементах в конце
      return `${arr.slice(0, maxNamesToShow).map((item) => item.userName).join(', ')} ${this.$t('and another')} ${arr.length - maxNamesToShow}`;
    },

    showUserChip(groupId, userId, userName) {
      if (!this.groupUserSearch[groupId] || !userId || !userName) {
        return true;
      }

      return this.groupUserSearch[groupId]?.includes(String(userId))
        || this.groupUserSearch[groupId]?.includes(userName)
        || String(userId).includes(this.groupUserSearch[groupId])
        || userName.includes(this.groupUserSearch[groupId]);
    },

    clickHandler(groupId, userId) {
      if (this.selected[groupId]?.[userId]) {
        const { [userId]: exclude, ...other } = this.selected[groupId];
        this.selected = { ...this.selected, [groupId]: other };
      } else {
        this.selected = { ...this.selected, [groupId]: { ...this.selected[groupId], [userId]: true } };
      }
    },

    getAddButtonText(arr) {
      if (arr && arr.length >= 1) {
        return this.$t('Add many to group', { users: arr.length });
      }
      return this.$t('Add one to group');
    },

    async addUsersToGroupHandler(groupId) {
      this.setGeneralProgress(true);
      if (this.mapOfIdLists[groupId]) {
        const userIdList = Object.values(this.mapOfIdLists[groupId]);
        if (groupId && this.mapOfIdLists[groupId] && userIdList.length) {
          this.confirmAction({
            title: this.$t('confirmAction.Are you sure you want to add this user(s) to group?'),
            callback: async () => {
              this.loader = true;
              try {
                userIdList.forEach(async (user) => {
                  await this.addUsersToGroup({
                    userId: [String(user)],
                    id: groupId,
                  });
                });
                await this.getGroups();
                this.setSuccessNotification('You successfully added user(s) to group');
              } catch (error) {
                this.setErrorNotification(error.message);
              }
              this.loader = false;
            },
          });
        }
      }
      this.setGeneralProgress(false);
    },

    async removeUserFromGroup({
      groupId, userId,
    }) {
      this.setGeneralProgress(true);
      if (groupId && userId) {
        this.confirmAction({
          title: this.$t('confirmAction.Are you sure you want to delete this user from group?'),
          callback: async () => {
            this.loader = true;
            try {
              await this.removeUsersFromGroup({
                userId: [userId],
                id: groupId,
              });
              await this.getGroups();
              this.setSuccessNotification('You successfully deleted user from group');
            } catch (error) {
              this.setErrorNotification(error.message);
            }
            this.loader = false;
          },
        });
      }
      this.setGeneralProgress(false);
    },
  },
};
